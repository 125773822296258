import { useState, useEffect, useMemo } from "react";
import { Button, Divider, MenuProps, message } from "antd";
import bluePencil from "../../../../shared/assets/images/largBluePencil.svg";
import trash from "../../../../shared/assets/images/Trash.svg";
import check from "../../../../shared/assets/images/Check.svg";
import eye from "../../../../shared/assets/images/Eye.svg";
import EditMode from "./EditMode";
import { IKit, IKitQuestion, IKitSection, ISection } from "../../../../shared/utils/interfaces/interviewKit";
import { PreviewQuestions } from "../kit/PreviewQuestions";
import { useDispatch, useSelector } from "react-redux";
import { setShowPreview, selectGenerateSectionWithAI, setGenerateSectionWithAI, selectSelectedTemplate, selectSelectedTemplateData, setSelectedTemplateData, selectOldSelectedTemplateData, setOldSelectedTemplateData, setIsClickingUseContent } from "../../slice/interviewSlice";
import { GenerateNewSectionWithAI } from "./GenerateNewSectionWithAi";
import { useGenerateNewTemplateSectionWithAiMutation, useGetInterviewTemplateByIdQuery } from "../../slice/interviewApiSlice";
import LazyLoader from "../../../../shared/components/LazyLoader/load";
import { Warning } from "../../../../shared/components/Warning/Warning";
import { errorMessage, apiStatuses } from "../../../../shared/utils/constantData";
import { useUpdateTemplateMutation } from "../../slice/interviewApiSlice";
import AddSectionDropdown from "../../../../shared/components/Dropdown/AddSectionDropdown";
import { useSearchParams } from "react-router-dom";

const ViewMode = () => {
  const [sections, setSections] = useState<ISection[]>([]);
  const dispatch = useDispatch();
  const isGeneratingSection = useSelector(selectGenerateSectionWithAI);
  const selectedTemplate = useSelector(selectSelectedTemplate)
  const templateData = useSelector(selectSelectedTemplateData)
  const oldTemplateData = useSelector(selectOldSelectedTemplateData)
  const [search] = useSearchParams();
  const { isFetching, isSuccess, data, isError } = useGetInterviewTemplateByIdQuery(
    search?.get('id'),
    {
      skip: !Number(selectedTemplate?.key) || !search?.get('id'), refetchOnMountOrArgChange: true
    }
  )
  const [updateTemplate, { isLoading: isLoadingSaving, isError: isErrorSaving, isSuccess: isSuccessSaving, error: savingError, data: newTemplateData }] = useUpdateTemplateMutation();
  const [GenerateNewTemplateSectionWithAi, { isLoading: isLoadingGenerate, isSuccess: isSuccessGenerate, isError: isErrorGenerate, error: ErrorGenerate, data: dataGenerate }] = useGenerateNewTemplateSectionWithAiMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectedTemplateData((data?.data)))
      dispatch(setOldSelectedTemplateData((data?.data)))
    }
  }, [isSuccess, data, dispatch])

  useEffect(() => {
    if (templateData?.data?.sections?.evaluationMetrics) {
      const transformedSections = templateData.data.sections.evaluationMetrics.map(
        (section: IKitSection) => ({
          ...section,
          metrics: section?.metrics?.map((question: IKitQuestion) => ({
            question: question?.question || "",
            metric: question?.metric || "",
            isOpen: false,
            rating: question?.rating || 0,
            note: question?.note || "",
          })),
        })
      );
      setSections(transformedSections);
    }
  }, [templateData]);

  useEffect(() => {
    return () => {
      dispatch(setGenerateSectionWithAI(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccessSaving) {
      message.success('Interview kit Updated Successfully', 2)
      const updatedData = newTemplateData.data;
      dispatch(setSelectedTemplateData(updatedData as IKit));
      dispatch(setOldSelectedTemplateData(updatedData as IKit));
    } else if (isErrorSaving) {
      message.error(savingError?.status === apiStatuses.badRequest ? errorMessage : savingError?.data?.message || errorMessage, 5)
    }
  }, [isSuccessSaving, isErrorSaving, savingError, newTemplateData, dispatch])
  const updatedTemplateDataWithGeneratedSections = (isOldData?: boolean) => {
    const AllSections = [
      ...templateData?.data?.sections?.evaluationMetrics,
      { ...dataGenerate?.data, editable: true } as IKitSection,
    ];
    const updatedTemplate = {
      ...templateData,
      data: {
        ...templateData?.data,
        sections: {
          ...templateData?.data?.sections,
          evaluationMetrics: AllSections,
        },
      },
    }
    isOldData ? dispatch(setOldSelectedTemplateData((updatedTemplate as IKit)))
      : dispatch(setSelectedTemplateData((updatedTemplate as IKit)))
  }
  useEffect(() => {
    if (isSuccessGenerate) {
      updatedTemplateDataWithGeneratedSections();
      updatedTemplateDataWithGeneratedSections(true);
      dispatch(setGenerateSectionWithAI(false))
      dispatch(setIsClickingUseContent(true))
    }
    else if (isErrorGenerate) message.error(ErrorGenerate?.status === apiStatuses.badRequest ? errorMessage : ErrorGenerate?.data?.message || errorMessage, 5)
    //eslint-disable-next-line
  }, [isSuccessGenerate, isLoadingGenerate, isErrorGenerate, ErrorGenerate, dataGenerate, dispatch])

  const isSaveBtnDisabled = useMemo(() => {
    const deletedSection =
      newTemplateData
        ? newTemplateData?.data.data?.sections?.evaluationMetrics?.length === templateData?.data?.sections?.evaluationMetrics?.length
        : data?.data?.data?.sections?.evaluationMetrics?.length === templateData?.data?.sections?.evaluationMetrics?.length;

    const isSectionUpdated = templateData?.data?.sections?.evaluationMetrics.some(section => section?.updated);
    const isEditingSections = templateData?.data?.sections?.evaluationMetrics.some(section => section?.editable);
    const sectionsLength = templateData?.data?.sections?.evaluationMetrics.length;
    return isLoadingSaving || isErrorSaving || (!isSectionUpdated && deletedSection) || isEditingSections || !sectionsLength || isGeneratingSection;
  }, [isLoadingSaving, templateData, isErrorSaving, data, newTemplateData, isGeneratingSection])

  const updatedTemplateDataWithSections = (updatedSections: IKitSection[], isOldData?: boolean) => {
    const updatedTemplate = {
      ...templateData,
      data: {
        ...templateData.data,
        sections: {
          ...templateData.data.sections,
          evaluationMetrics: updatedSections,
        },
      },
    }
    isOldData ? dispatch(setOldSelectedTemplateData((updatedTemplate as IKit)))
      : dispatch(setSelectedTemplateData((updatedTemplate as IKit)))
  }
  const addNewSection = () => {
    if (isGeneratingSection) dispatch(setGenerateSectionWithAI(false))
    let AllSections = [...templateData?.data?.sections?.evaluationMetrics];
    const newSection = {
      id: Math.floor(Math.random() * 100) + 1,
      title: "",
      metrics: [],
      isNew: true,
      editable: true,
    }
    AllSections = [...AllSections, newSection]
    const updatedSections = templateData?.data?.sections?.evaluationMetrics?.map((section) => {
      return { ...section, editable: false };
    }).filter(({ title, metrics }) => title && metrics.length)
    AllSections = [...updatedSections, newSection]
    updatedTemplateDataWithSections(AllSections)
  }
  const handleSelectNewSectionItem: MenuProps["onClick"] = (e) => {
    if (e?.key === "1") {
      const updatedSections = templateData?.data?.sections?.evaluationMetrics?.map((section) => {
        return { ...section, editable: false };
      }).filter(({ title, metrics }) => title && metrics.length)
      updatedTemplateDataWithSections(updatedSections)
      dispatch(setGenerateSectionWithAI(true))
    } else if (e?.key === "2") {
      addNewSection()
    }
  };
  const EditQuestions = (id: number) => {
    dispatch(setIsClickingUseContent(false))
    if (isGeneratingSection) dispatch(setGenerateSectionWithAI(false))
    const updatedSections = (oldTemplateData?.data?.sections?.evaluationMetrics || templateData?.data?.sections?.evaluationMetrics)?.map((sec: IKitSection) => ({
      ...sec,
      editable: sec.id === id ? true : false,
    }));
    updatedTemplateDataWithSections(updatedSections);
  }
  const showPreviews = () => {
    dispatch(setShowPreview(true));
  }
  const deleteSection = (id: number) => {
    const updatedSections = templateData?.data?.sections?.evaluationMetrics?.filter((section) => section?.id !== id);
    updatedTemplateDataWithSections(updatedSections);
    updatedTemplateDataWithSections(updatedSections, true);
  }

  const sectionsTitlesValidation = () => {
    const updatedSections = templateData?.data?.sections?.evaluationMetrics.map((section) => !section?.title ? { ...section, hasNoTitle: true } : { ...section, hasNoTitle: false })
    updatedTemplateDataWithSections(updatedSections)
    return templateData?.data?.sections?.evaluationMetrics.every(({ title }) => title)
  }
  const handleSave = async () => {
    const isSectionsHavingTitle = sectionsTitlesValidation()
    if (isSectionsHavingTitle) {
      await updateTemplate({ templateId: templateData.id, data: templateData.data })
    }
  }
  const handleGenerateNewSection = async (title: string) => {
    await GenerateNewTemplateSectionWithAi({ templateId: templateData?.id, title });
  };
  return (
    <div>
      <div className="flex justify-end w-full gap-3 -mt-5 md:-mt-20">
        <Button
          onClick={() => {
            showPreviews()
          }}
          className="w-[7rem] h-9 bg-white rounded-md text-sm font-medium border border-[#D1D5DB] hover:bg-[#EEF2FF] text-[#374151] flex items-center justify-center gap-2 mt-5 disabled:bg-[#C7D2FE]"
        >
          <div>Preview</div>
          <img src={eye} alt="" className="w-5 h-5" />
        </Button>
        <Button
          onClick={handleSave}
          disabled={isSaveBtnDisabled}
          loading={isLoadingSaving}
          className="w-[10rem] h-9 bg-[#4F46E5] rounded-md text-sm font-medium !text-white hover:bg-[#5145CD] flex items-center justify-center gap-2 mt-5 disabled:bg-[#C7D2FE]"
        >
          <div>Save Template</div>
          <img src={check} alt="" />
        </Button>
      </div>
      {
        isFetching ? <div className="w-full h-[34.5rem] flex flex-col justify-center items-center">
          <LazyLoader />
          <h3 className="mt-2 text-indigo-700">Retrieving template..</h3>
        </div>
          : isSuccess ?
            <div className="h-[70vh]">
              {templateData?.data?.sections?.evaluationMetrics?.map((item: IKitSection) => {
                return (
                  <div className=" w-full md:w-[65%] mx-auto my-8">
                    <div className="flex items-center justify-between h-7 ">
                      <div className="h-full flex items-center text-xs font-bold text-[#111827] uppercase">
                        {item?.isNew && !item?.title ? "New section" : item?.title}
                      </div>
                      {
                        (!item?.editable && item?.title) && (
                          <div className="w-[6.5rem] h-full rounded flex justify-between items-center">
                            <button
                              onClick={() => EditQuestions(item.id)}
                              className="w-full md:w-[65%] h-full flex justify-center items-center gap-x-2 ml-2"
                            >
                              <div className="text-xs font-medium text-[#4338CA]">

                                Edit
                              </div>
                              <img src={bluePencil} alt="" className="w-4 h-4" />
                            </button>
                            <Divider type="vertical" className="border-[#C0C0CF] h-5" />
                            <img
                              src={trash}
                              alt="delete"
                              onClick={() => deleteSection(item.id)}
                              className="w-4 h-4 mr-2 hover:cursor-pointer"
                            />
                          </div>
                        )
                      }
                    </div>
                    {item?.editable ? (
                      <EditMode section={item} />
                    ) : (
                      <div>
                        {item?.metrics?.map((metric: IKitQuestion, index: number) => (
                          <div key={index}>
                            <li className="text-[#4B5563] mb-1 px-2">{metric?.question}</li>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
              {isGeneratingSection && <GenerateNewSectionWithAI onGenerateNewSection={handleGenerateNewSection} isLoading={isLoadingGenerate} />}
              <div className="w-full md:w-[65%] mx-auto my-8">
                <AddSectionDropdown onSelect={handleSelectNewSectionItem} />
              </div>
              <div className="h-10"></div>
            </div>
            : isError ? <Warning title={errorMessage} message='Please refresh to try again' btnText='Refresh' />
              : null
      }
      <PreviewQuestions kitTemplateData={sections} kitTemplateTitle={templateData?.data?.title} />
    </div>
  );
};

export default ViewMode;