import { Tag } from 'antd';
import errorAiIcon from "../../../../shared/assets/images/errorAiIcon.svg";
import successAiIcon from "../../../../shared/assets/images/successAiIcon.svg";
import warningAiIcon from "../../../../shared/assets/images/warningAiIcon.svg";

export default function CVScore({ cvScore, className }: { cvScore: number | null, className?: string }) {
    const status = typeof cvScore !== 'number' ? 'warning' : cvScore > 6 ? 'success' : cvScore > 3 ? 'warning' : 'error';
    return (
        <Tag bordered={false} className={`ant-tag-${status} mb-1.5 mr-0 capitalize ${className ?? ''}`}>
            {typeof cvScore === 'number' ? <> <img className="inline mr-1" src={status === 'success' ? successAiIcon : status === 'warning' ? warningAiIcon : errorAiIcon} alt="ai" />Score <span className='font-bold'>{cvScore}/10</span></> : 'Pending'}
        </Tag>
    )
}