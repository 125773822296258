import { Dropdown, Button } from "antd";
import BluePlusIcon from "../../../shared/assets/images/bluePlus.svg";
import GreyPlusIcon from "../../../shared/assets/images/greyPlus.svg";
import { newSectionItems } from "../../../shared/utils/constantData";
import { MenuProps } from "antd";

const AddSectionDropdown = ({ onSelect, disabled }: { onSelect: MenuProps['onClick'], disabled?: boolean }) => {
  return (
    <div className="w-full mx-auto my-8">
      <Dropdown
        menu={{ items: newSectionItems, onClick: onSelect }}
        trigger={["click"]}
        className="mb-6"
        placement="topRight"
        dropdownRender={(menu) => (
          <div className="overflow-y-auto absolute top-3 left-32 bg-white border w-fit border-gray-300 rounded-xl shadow-md max-h-52 hover:!bg-[#EEF2FF]">
            {menu}
          </div>
        )}
        disabled={disabled}
      >
        <Button
          className="border-dashed relative w-full cursor-pointer whitespace-nowrap text-indigo-700 flex !bg-transparent py-7 pl-6 items-center shadow-none text-xs !font-medium"
        >
          Add section
          <img src={disabled ? GreyPlusIcon : BluePlusIcon} className="inline ms-2" alt="+" />
        </Button>
      </Dropdown>
    </div>
  );
};


export default AddSectionDropdown;