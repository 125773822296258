import { Divider, Dropdown, Menu } from "antd";
import { IArrowDropdownProps } from "../../utils/interfaces/interviewKit";
import { useDispatch } from 'react-redux'
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ArrowDropdown: React.FC<IArrowDropdownProps> = ({
  list,
  imageSrc,
  selectedTemplate,
  setSelectedTemplate,
  withDivider,
}) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate()
  const listMenu = (
    <Menu className="max-w-96 max-h-52 absolute !rounded-xl !px-2 !py-1 left-5 md:left-0 overflow-y-auto">
      {list.length > 0 ? (
        list?.map((item: any, index: number) => (
          <div key={index}>
            {index === list.length - 1 && withDivider && (
              <Divider className="my-1 border-t border-gray-300" />
            )}
            <Menu.Item
              key={item?.key}
              disabled={item?.disabled}
              onClick={(e) => {
                e.domEvent.stopPropagation();
                if (Number(item?.key)) {
                  dispatch(setSelectedTemplate(item));
                  navigate(`/settings/templates/interview`)
                  localStorage.setItem('isCreating', 'false')
                }
                else item?.label?.props?.onClick()
                setDropdownOpen(false);
              }}
              className={`w-full h-10 rounded-1.5 !text-4 font-normal flex !bg-white !my-1 !p-0`}
            >
              <div className="flex items-center gap-2">
                {
                  <div
                    className={`px-4 py-2 w-full text-base rounded hover:!bg-[#EEF2FF]
                      } ${selectedTemplate?.key === item?.key ? 'bg-indigo-50' : ''}`}
                  >
                    {item?.label}
                  </div>
                }
              </div>
            </Menu.Item>
          </div>
        ))
      ) : (
        <Menu.Item>No items available</Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <Dropdown
        className="relative cursor-pointer"
        open={dropdownOpen}
        onOpenChange={(open) => {
          setDropdownOpen(open);
        }}
        trigger={["click"]}
        dropdownRender={() =>
          listMenu
        }>
        <img src={imageSrc} alt="Dropdown Icon" className={`w-4 h-4 mt-1`} />
      </Dropdown>
    </div>
  );
};

export default ArrowDropdown;